.container {
    padding: 0px 40px;
}

.head {
    text-align: center;
    font-family: Vazir;
    font-weight: bold;
    margin-top: 20vh;
}

.left,
.right {
    display: flex;
    justify-content: center;
    margin: 7vh auto;
    background-color: #fff;
    width: fit-content;
    padding: 30px;
    border-radius: 20px;
    -webkit-box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
}

.left img,
.right img {
    width: 400px;
    height: 400px;
    border-radius: 20px;
}

.left>div>p,
.right>div>p {
    direction: rtl;
    padding: 20px;
    width: 500px;
    text-align: center;
}

.left {
    flex-direction: row;
}

.right {
    flex-direction: row-reverse;
}

.description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.description>ul {
    direction: rtl;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    width: 40%;
    margin: 0 auto;
}

.description>ul>li {
    list-style: circle;
    white-space: nowrap;
}

@media (max-width:768px) {

    .left,
    .right {
        flex-direction: column;
      
    }

    .left img,
    .right img {
        width: 250px;
        height: 250px;

    }

    .left>div>p,
    .right>div>p {
        padding: 10px;
        width: 200px;
    }

    .description>ul {
        width: 90%;
    }
}