.container {
    width: 100%;
    padding-top: 20px;
    bottom: 0px;
    background-color: rgb(22, 6, 116);
    color: #fff;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px -10px 21px -7px rgba(0, 0, 0, 0.38);
    -webkit-box-shadow: 0px -10px 21px -7px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px -10px 21px -7px rgba(0, 0, 0, 0.38);
}

.icon {
    height: 20px;
    width: 20px;
    padding-left: 5px;
}

.subContainer {

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
}

.firstContent {
    direction: rtl;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
}

.firstContent>p {
    width: 300px;
    padding-top: 5px;
}

.secondContent {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    direction: rtl;

}

.bottomNav>ul {
    height: 100%;
    text-align: center;
}



.bottomNav>ul>li>a {
    color: #fff;
    line-height: 30px;
}

.socialMedia {
    display: flex;
    justify-content: center;
}

.socialMedia>a {
    padding-right: 20px;
}

.socialMedia>a>img {
    width: 48px;
    height: 48px;
}

.map {
    border-radius: 20px;
    margin-left: 20px;

}

.introduction {
    width: 200px;
}

.creatorSection {
    padding-top: 20px;

}

.creatorSection>p {
    font-size: small;
    text-align: center;
    font-family: 'Yekan';
}

.creatorSection>p>a {
    color: #86ddf6;
}

@media (max-width:768px) {

    .subContainer,
    .firstContent,
    .secondContent {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }

    .socialMedia {
        padding-top: 20px;
    }

    .bottomNav {
        display: none;
    }
}