.container {
    width: 70%;
    margin: 15vh auto;
}

.container>h2 {
    text-align: center;
    font-family: Vazir;
    font-weight: bold;

}

.icon {
    height: 20px;
    width: 20px;
    padding-left: 5px;
}

.subContainer>img {
    width: 567px;
    height: 567px;
    border-radius: 20px;

}

.subContainer {
    display: flex;
    padding: 20px 15px;
    justify-content: space-between;
}

.description {
    direction: rtl;
}

.content {
    background-color: #fff;
    padding: 50px;
    border-radius: 20px;
    -webkit-box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 33px -21px rgba(0, 0, 0, 0.75);
}

.contactUs {
    margin-top: 40px;
    line-height: 30px;
}

@media (max-width:768px) {
    .container {
        width: 100%;
    }

    .subContainer>img {
        width: 100%;
        height: auto;
    }

    .subContainer {
        flex-direction: column;
    }
}