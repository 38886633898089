.container {
    margin-top: 0;
    background-image: url("../asset/images/slider/manufacture.jpg");
    width: 100%;
    height: 100vh;
    background-repeat: round;
    box-shadow: 0px -20px 20px 0px white inset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subcontainer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    padding-top: 7vh;
    width: 100%;

}

.slider {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.slider>img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.slider>p {
    font-weight: 400;
    padding-top: 3vh;
    font-size: large;
}

@media (max-width:768px) {
    .slider>img {
        display: none;
    }

 
}