@font-face {
    font-family: Yekan;
    src: url('./asset/fonts/yekan/Yekan.eot');
    src: url('./asset/fonts/yekan/Yekan.eot?#iefix') format('FontName-opentype'),
        url('./asset/fonts/yekan/Yekan.woff') format('woff'),
        url('./asset/fonts/yekan/Yekan.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: Vazir;
    src: url('./asset/fonts/vazir/Vazir-Light-FD-WOL.eot');
    src: url('./asset/fonts/vazir/Vazir-Light-FD-WOL.eot?#iefix') format('FontName-opentype'),
        url('./asset/fonts/vazir/Vazir-Light-FD-WOL.woff') format('woff'),
        url('./asset/fonts/vazir/Vazir-Light-FD-WOL.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap; 
}

@font-face {
    font-family: Vazir;
    src: url('./asset/fonts/vazir/Vazir-Bold-FD-WOL.eot');
    src: url('./asset/fonts/vazir/Vazir-Bold-FD-WOL.eot?#iefix') format('FontName-opentype'),
        url('./asset/fonts/vazir/Vazir-Bold-FD-WOL.woff') format('woff'),
        url('./asset/fonts/vazir/Vazir-Bold-FD-WOL.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap; 
}

* {
    font-family: Vazir;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

li,
a {
    text-decoration: none;
    list-style: none;
    color: inherit;
}

body {
    background-color: #ffffff;
}

a:hover {
    color: #537bb1;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #ffffff;
}

::-webkit-scrollbar-thumb {
    background: #262626;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #0a4eec;
}