.container {
    display: flex;
    font-family: Vazir;
    font-weight: bold;
    top: 0;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 10px 21px -7px rgba(0, 0, 0, 0.38);
    -webkit-box-shadow: 0px 10px 21px -7px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 10px 21px -7px rgba(0, 0, 0, 0.38);
}

.logo {
    width: 50px;
    height: 50px;
    padding: 20px 0 10px 30px;
}

.topNav>ul {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    width: 50vw;
}

@media (max-width:768px) {

    .topNav>ul {
        width: fit-content;
        padding-right: 15px;
    }

    .topNav>ul>li {
        padding-left: 20px;

    }

    .topNav>ul>li>a {
        font-size: 0.8rem;
    }
}